.body{
  background-color: #000000;
}

.bg-myRed{
  background-color: crimson;
}

.content{
  top: 0;
  bottom: 0;
  width: 100%;
  /* width: var(--widthmod); */
  min-height: var(--heightmod);
  max-width: 100%;
  /* padding: 12px;
  height: 3000px; */
  position: absolute;
}

.bg-dark {
  background-color: transparent;
}

.coloring{
  color: black;
}

.background-wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-color: black;
}

.background-wrapper video {
  position: fixed;
  width: 100%;
  height: auto;
}

.bgvideo{
  position: absolute;

  width: 100%;
  height: auto;

  z-index: -8;
  opacity: 1;
  transition: opacity 300ms;
  overflow: hidden;
}

.image-background {
  position: absolute;

  --varadjust: calc(var(--widthmod) / 1920);
  width: calc(1920 * var(--varadjust));
  height: calc(1080 * var(--varadjust));
  z-index: -9;

  opacity: 1;
  transition: opacity 300ms;
}

.landing {
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.welcomeText {
  width: 25%;
  height: 25%;
  z-index: -1;
  transform: rotate(90deg);
  opacity: 70%;
}

.logochain {
  width: 10%; 
}

#logo {
  display: flex;
  position: absolute;
  margin-left: 50%;
}

.Keybinds {
  color: #ffff;
  background-color:#0000006e;
}

.Keybinds h1 {
  text-decoration: underline;
  text-align: center;
  font-family: 'Playfair Display';
}

#tablecontainer {
  display: flex;
  justify-content: space-evenly;
  color: #ffff;
  
}

#keytable table {
  width: 115%;
  height: 10px;
  float: left;
  padding: 5px;
}

#commandtable table {
  width: 115%;
  height: 10px;
  float: left;
  padding: 5px;
}

#phrasetable table {
  width: 115%;
  height: 10px;
  float: left;
  padding: 5px;
}
  
th {
  border-bottom: 1px solid white;
  font-size: 20px;
  font-family: 'Playfair Display';
}
  
td {
  text-align: left;
}

.faq h1{
  color: #ffff;
  text-align: center;
  padding-top: 5%;
  text-decoration:underline;
}

.faq {
  padding-right: 24%;
  padding-left: 24%;
}

.faqaccordion {
  background-color: white;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

.footer {
  text-align:center;
  color: white;
  font-family: 'Playfair Display';
  font-size: 25px;
  padding-left: 20%;
  padding-right: 20%;
}

.infosection {
  background-image: linear-gradient(0deg, #000000, transparent);
}

.discord {
  text-align: center;
  color: white;
  font-family: 'Playfair Display';
  font-size: 25px;
}

.twitter {
  text-align: center;
  color: white;
  font-family: 'Playfair Display';
  font-size: 25px;
}

@media only screen and (max-device-width: 480px) {
  .background-wrapper video {
    position: fixed;
    width: auto;
    height: auto;
  }

  .landing {
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .welcomeText {
    width: 55%;
    height: 55%;
    z-index: -1;
    transform: rotate(90deg);
    opacity: 70%;
  }

    
  .Keybinds {
    color: #ffff;
    background-color:#0000006e;
  }

  .Keybinds h1 {
    text-decoration: underline;
    text-align: center;
    font-family: 'Playfair Display';
  }

  #tablecontainer {
    flex-direction: column;
    color: #ffff;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  #keytable table {
    width: 100%;
  }

  #commandtable table {
    width: 100%;
  }

  #phrasetable table {
    width: 100%;
  }

  .footer {
    text-align:center;
    color: white;
    font-family: 'Playfair Display';
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  }
  
}